<template>
  <router-link class="portfolio" :to="{name: 'Portfolio', params: { id }}">
    <img class="portfolio__image" :src="imageURL" :alt="title">
    <h4 class="portfolio__title">{{ title }}</h4>
    <p class="portfolio__description">{{ description }}</p>

    <div class="portfolio__tags">
      <span class="portfolio__tag" v-for="(tag, index) in tags" :key="index">{{ tag }}</span>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'PortfolioListItem',

  props: {
    id: {
      type: Number,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      required: true
    }
  },

  computed: {
    imageURL() {
      return require(`@/assets/portfolio/${this.image}`)
    },
  }
}
</script>

<style scoped>
.portfolio {
  text-decoration: none;
  outline: none;
}

.portfolio:focus .portfolio__image {
  outline: 2px solid var(--black);
}

.portfolio__image {
  width: 100%;
  margin-bottom: 2.5em;
}

.portfolio__title {
  margin-bottom: 0.02em;
}

.portfolio__description {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 140%;
  color: var(--light-black);

  margin-bottom: 1.38em;
}

.portfolio__tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.4em;
}

.portfolio__tag {
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 140%;
  color: var(--light-black);

  border: 1px solid var(--light-gray);
  padding: 0.57em 1em;
}
.portfolio:focus .portfolio__tag {
  color: var(--black);
  font-weight: 500;
  border: 1px solid var(--black);
}
@media (max-width: 426px) {
  .portfolio__tag {
    padding: 0.35em 0.71em;
  }
}
</style>
