<template>
  <main class="portfolio-list">
    <h2 class="portfolio-list__header">Примеры работ</h2>
    <section class="portfolio-list__items">
      <PortfolioListItem v-for="(portfolio, index) in portfolios" :key="index" v-bind="portfolio"/>
    </section>
  </main>
</template>

<script>
import PortfolioListItem from '@/components/Portfolio/PortfolioListItem.vue'

export default {
  name: 'PortfolioList',
  components: { PortfolioListItem },
  data() {
    return {
      portfolios: [
        {
          id: 1,
          image: 'PODO.png',
          title: 'PODO',
          description: 'приложение для знакомств',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Продвижение', 'Поддержка']
        },
        {
          id: 2,
          image: 'SpicyDrop.png',
          title: 'Spicy Drop',
          description: 'кейсы CS GO',
          tags: ['Дизайн', 'Разработка']
        },
        {
          id: 3,
          image: 'HeroCity.png',
          title: 'Город Героев',
          description: 'система геймификация',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Поддержка']
        },
        {
          id: 4,
          image: 'Qbik.png',
          title: 'Qbik',
          description: 'конструктор журналов',
          tags: ['Дизайн', 'Разработка']
        },
        {
          id: 5,
          image: 'RCCash.png',
          title: 'RC.Cash',
          description: 'кэшбэк сервис',
          tags: ['Дизайн', 'Разработка', 'Поддержка']
        },
        {
          id: 6,
          image: 'AlongTheWay.png',
          title: 'По пути',
          description: 'поиск совместных городских поездок',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Поддержка']
        },
        {
          id: 7,
          image: 'VSH25.png',
          title: 'VSH25',
          description: 'приложение для улучшения сна',
          tags: ['Дизайн', 'Разработка']
        },
        {
          id: 8,
          image: 'TELEPORT.png',
          title: 'TELEPORT',
          description: 'Такси!',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Поддержка']
        },
        {
          id: 9,
          image: 'JeansCity.png',
          title: 'Jean’s City ',
          description: 'интернет-магазин',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Поддержка']
        },
        {
          id: 10,
          image: 'RCHouse.png',
          title: 'RC.House',
          description: 'жилищно-накопительного кооператив',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Поддержка']
        },
        {
          id: 11,
          image: 'SpinIT.png',
          title: 'Spin IT',
          description: 'онлайн-курсы',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Продвижение', 'Поддержка']
        },
        {
          id: 12,
          image: 'Primary.png',
          title: 'Primary',
          description: 'элитное такси',
          tags: ['Концепция', 'Дизайн', 'Разработка', 'Продвижение', 'Поддержка']
        },
        {
          id: 13,
          image: 'QTKassa.png',
          title: 'QT Kassa',
          description: 'онлайн-платформа для кинотеатра',
          tags: ['Концепция', 'Дизайн', 'Разработка']
        },
      ]
    }
  }
}
</script>

<style scoped>
.portfolio-list__header {
  margin: 4.027em 0 2em;
}
@media (max-width: 1025px) {
  .portfolio-list__header {
    margin: 2.6em 0 1.7em;
  }
}
@media (max-width: 720px) {
  .portfolio-list__header {
    margin: 2em 0 1.33em;
  }
}
@media (max-width: 425px) {
  .portfolio-list__header {
    margin: 1.92em 0 1.85em;
    font-size: 1.625rem;
  }
}

.portfolio-list__items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4.5em 1.5625em ;

  margin-bottom: var(--section-bottom-margin);
}
@media (max-width: 1100px) {
  .portfolio-list__items {
    grid-template-columns: 1fr 1fr;
    gap: 3em 1.5625em ;
    margin-bottom: 5.3125em;
  }
}
@media (max-width: 800px) {
  .portfolio-list__items {
    grid-template-columns: 1fr;
  }
}

main {
  width: 81.875em;
  margin: 0 auto;
  box-sizing: border-box;
}
@media (max-width: 1367px) {
  main {
    width: 100%;
    padding: 0 65px;
  }
}
@media (max-width: 1025px) {
  main {
    padding: 0 40px;
  }
}
@media (max-width: 426px) {
  main {
    padding: 0 25px;
  }
}
@media (max-width: 321px) {
  main {
    padding: 0 15px;
  }
}

</style>
